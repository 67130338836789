<template>
  <address>
    <strong>{{ recipient }}</strong><br>
    {{ address }}<br>
    {{ postalcode }}<br>
    {{ city }}<br>
    {{ country }}
  </address>
</template>

<script>
export default {
    props: ["recipient", "address", "postalcode", "city", "country", "type", "editable"]
}
</script>