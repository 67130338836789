import ApiService from "./api.service.js";

function PrintError(status, message) {
    this.status = status
    this.message = message
}

const PrintService = {
    print: async function(id) {
        try {
            ApiService.setHeader()

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/transaction/" + id + "/print", { responseType: 'blob' })

            return response.data
        } catch (e) {
            console.log("error", e)
            throw new PrintError(e, e)
        }
    },
};

export default PrintService;
export { PrintService, PrintError };