<template>
    <v-container fluid>
        <customer-selection />

        <v-tabs v-model="tab">

            <v-tab>Angebote</v-tab>    
            <v-tab>Bestellungen</v-tab>
            <v-tab>Lieferungen</v-tab>
            <v-tab>Rechnungen</v-tab>
            <v-tab>Dashboard</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
             <!-- Tab Quotes -->
            <v-tab-item>
                <transactions-list :transactions="quotes" :reorder="true" :type="0" />
                <div class="text-center mt-10" v-if="quotes && quotes.pages">
                    <v-pagination
                        v-model="quotesPage"
                        :length="quotes.pages"
                    ></v-pagination>
                </div>
            </v-tab-item>
            <!-- Tab Orders -->
            <v-tab-item>
                <transactions-list :transactions="orders" :reorder="true" :type="1" />
                <div class="text-center mt-10" v-if="orders && orders.pages">
                    <v-pagination
                        v-model="ordersPage"
                        :length="orders.pages"
                    ></v-pagination>
                </div>
            </v-tab-item>

            <!-- Tab Deliveries -->
            <v-tab-item>
                <transactions-list :transactions="deliveries" :reorder="false" type="2" />
                <div class="text-center mt-10" v-if="deliveries && deliveries.pages">
                    <v-pagination
                        v-model="deliveriesPage"
                        :length="deliveries.pages"
                    ></v-pagination>
                </div>
            </v-tab-item>

            <!-- Tab Invoices -->
            <v-tab-item>
                <transactions-list :transactions="invoices" :reorder="false" type="3" />
                <div class="text-center mt-10" v-if="invoices && invoices.pages">
                    <v-pagination
                        v-model="invoicesPage"
                        :length="invoices.pages"
                    ></v-pagination>
                </div>
            </v-tab-item>
            <!-- Dashboard -->
             <v-tab-item>
                <div v-for="dashboard in feeds" :key="dashboard.ID">
                    <v-card class="mb-5" elevation="0" outlined v-if="dashboard.body">
                          <v-card-title>{{dashboard.title}}</v-card-title>
                        <v-card-text v-html="dashboard.body" class="clearfix"></v-card-text>
                    </v-card>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TransactionsList from "../components/transactions/TransactionsList"
import { DashboardService } from '@/services/dashboard.service';
import CustomerSelection from '../account/components/CustomerSelection.vue';

export default {
    components: {
        TransactionsList,
        CustomerSelection
    },

    data: () => ({
        tab: null,
        dashboards: [],
        dashboard: {},
        quotesPage: 1,    
        ordersPage: 1,
        deliveriesPage: 1,
        invoicesPage: 1
    }),

    created() {
        this.init();
    },

    computed: {
        ...mapGetters("account", ["quotes", "orders", "deliveries", "invoices"]),
        ...mapGetters("auth", ["userId"]),
        ...mapGetters("account", ["userAccount", "selectedAccount"]),

        accountId() {
            return this.selectedAccount ? this.selectedAccount.ID : null
        },
        feeds: function() {
            return this.dashboards?.data;
        }
    },

    watch: {
        accountId() {
            this.init()
        },

        quotesPage(val) {
            this.getQuotes((val - 1) * this.quotes.limit);
        },

        ordersPage(val) {
            this.getOrders((val - 1) * this.orders.limit);
        },

        deliveriesPage(val) {
            this.getDeliveries((val - 1) * this.deliveries.limit);
        },

        invoicesPage(val) {
            this.getInvoices((val - 1) * this.invoices.limit);
        },
    },

    methods: {
        ...mapActions("account", ["getTransactions"]),

        init() {
            this.getQuotes();
            this.getOrders();
            this.getDeliveries();
            this.getInvoices();
            this.getDashboards(); 
        },

        getQuotes(offset = 0) {
            this.getTransactions({
                accountId: this.userId,
                params: {
                    account: this.accountId,
                    type: 0,
                    limit: 50,
                    offset: offset
                }
            })
        },

        getOrders(offset = 0) {
            this.getTransactions({
                accountId: this.userId,
                params: {
                    account: this.accountId,
                    type: 1,
                    limit: 50,
                    offset: offset
                }
            })
        },

        getDeliveries(offset = 0) {
            this.getTransactions({
                accountId: this.userId,
                params: {
                    account: this.accountId,
                    type: 2,
                    limit: 50,
                    offset: offset
                }
            })
        },

        getInvoices(offset = 0) {
            this.getTransactions({
                accountId: this.userId,
                params: {
                    account: this.accountId,
                    type: 3,
                    limit: 50,
                    offset: offset
                }
            })
        },
        getDashboards(offset = 0) {
            DashboardService.list({
                accountId: this.userId,
                params: {
                    account: this.accountId,
                    type: 2,
                    limit: 50,
                    offset: offset
                }
                                
            })
            .then((res) => {
                    this.dashboards = res;
                })
        }
    }
}
</script>

<style>

</style>
