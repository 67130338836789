<template>
    <div>
        <v-data-table
            v-if="transactions"
            :headers="headers"
            :items="transactions.data"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:[`item.date`]="{ item }">
                {{ Number(item.date) | moment("DD.MM.YYYY") }}
            </template>
            <template v-slot:[`item.netamount`]="{ item }">
                {{ toPrice(item.netamount) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
                {{ statusMap[item.status] }}
            </template>
            <template v-slot:[`item.ID`]="{ item }">
                <v-btn icon @click.stop="getTransactionById(item.ID)">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn icon @click.stop="handlePrintTransactionClick(item.ID, item.transactionnum)">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="transactionViewDialog" max-width="900">
            <v-card v-if="transaction">
                <v-card-title>
                    #{{ transaction.transactionnum }}
                    <v-spacer />
                    <span class="font-weight-light">{{ Number(transaction.date) | moment("DD.MM.YYYY") }}</span>
                </v-card-title>
                <v-divider />

                <v-card-text class="mt-3">
                    <v-alert dense color="primary" outlined>Bestellstatus <strong>{{ statusMap[transaction.status] }}</strong></v-alert>
                </v-card-text>

                <v-card-text>
                    <v-row class="mb-1">
                        <v-col cols="12" md="6">
                            <h4>Rechnungsadresse</h4>
                            <address-component
                                :recipient="transaction.billingrecipient"
                                :address="transaction.billingaddress"
                                :postalcode="transaction.billingpostalcode"
                                :city="transaction.billingcity"
                                :country="transaction.billingcountry"
                                type="billingaddress" 
                                :editable="false" 
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <h4>Lieferadresse</h4>
                            <address-component
                                :recipient="transaction.shippingrecipient"
                                :address="transaction.shippingaddress"
                                :postalcode="transaction.shippingpostalcode"
                                :city="transaction.shippingcity"
                                :country="transaction.shippingcountry"
                                type="shippingaddress" 
                                :editable="false" 
                            />
                        </v-col>
                    </v-row>
                    <v-divider />
                </v-card-text>
                
                <v-card-text>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Artikelnummer</th>
                                    <th>Produktname</th>
                                    <th>Menge</th>
                                    <th>Einzelpreis</th>
                                    <th>Gesamtpreis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in transaction.items" :key="item.item">
                                    <td>{{ item.itemnum }}</td>
                                    <td>{{ item.name }}</td>
                                    <td class="text-right">{{ item.amount }}</td>
                                    <td class="text-right">
                                        <strike class="d-block" v-if="item.discount">{{ toPrice(item.sellingprice) }}</strike>
                                        {{ toPrice((item.sellingprice - item.rebate) * (1 - item.discount / 100) * (discount2(item))) }}
                                    </td>
                                    <td class="text-right">
                                        <strike class="d-block" v-if="item.discount">{{ toPrice(item.amount * item.sellingprice) }}</strike>
                                        {{ toPrice(item.amount * (item.sellingprice - item.rebate) * (1 - item.discount / 100) * (discount2(item))) }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr v-if="transaction.discount > 0">
                                    <th colspan="4" class="text-right">Zwischensumme (netto)</th>
                                    <th class="text-right">{{ toPrice(transaction.netamount + transaction.discount) }}</th>
                                </tr>

                                <tr v-if="transaction.discount > 0">
                                    <th colspan="4" class="text-right">Discount (netto)</th>
                                    <th class="text-right">-{{ toPrice(transaction.discount) }}</th>
                                </tr>

                                <tr>
                                    <th colspan="4" class="text-right">Summe (netto)</th>
                                    <th class="text-right">{{ toPrice(transaction.netamount) }}</th>
                                </tr>
                            </tfoot>
                        </template>
                    </v-simple-table>
                </v-card-text>

                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn v-if="reorder" :disabled="cart.length > 0" icon @click.stop="handlerReorderClick(transaction.items, transaction.account)">
                        <v-icon>mdi-cart</v-icon>
                    </v-btn>
                    <v-btn icon @click.stop="handlePrintTransactionClick(transaction.ID, transaction.transactionnum)">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PrintService } from "@/services/print.service"
import FileSaver from "file-saver"

// Components
import AddressComponent from '../../account/components/AddressComponent.vue';

export default {
    name: 'transaction-list',

    components: {
        AddressComponent
    },

    props: ['transactions', 'reorder', 'type'],

    data: () => ({
        transactionViewDialog: false,
        headers: [
            {
                text: "Transaktionsnummer",
                value: "transactionnum"
            },
            {
                text: "Kunde",
                value: "accountName"
            },
            {
                text: "Bestellt am",
                value: "date"
            },
            {
                text: "Summe (netto)",
                value: "netamount",
                align: "end"
            },
            {
                text: "Status",
                value: "status"
            },
            {
                text: "",
                value: "ID",
                align: "end"
            }
        ],
    }),
 
    computed: {
        ...mapGetters('account', ['transaction', 'selectedAccount']),
        ...mapGetters("app", ["config"]),
        ...mapGetters("cart", ["cart"]),
        ...mapGetters("product", ["product"]),

        statusMap() {
            return this.config.status_map;
        },
    },

    methods: {
        ...mapActions('account', ['getTransaction', 'unsetAccount', 'getAssignedAccount', 'setAccount']),
        ...mapActions("cart", ["upsertCartItem"]),
        ...mapActions("product", ["showProduct"]),

        discount2(item) {
            return item.discount2 ? (1 - item.discount2 / 100) : 1;
        },

        getTransactionById(transactionId) {
            this.getTransaction({
                transactionId: transactionId,
                params: {
                    type: this.type
                }
            })
                .then(() => {
                    this.transactionViewDialog = true;
                });
        },

        handlePrintTransactionClick(id, transactionnum) {
            PrintService.print(id)
                .then(res => {
                    var file = new File([res], `${transactionnum}.pdf`, { type: "application/pdf" })
                    FileSaver.saveAs(file)
                })
        },

        handlerReorderClick(items, account) {
            if (!this.selectedAccount) {
                this.unsetAccount()

                this.getAssignedAccount(account)
                    .then(() => {
                        this.setAccount(account);
                    });
            }
            
            items.forEach(item => {
                this.showProduct({ account: account, search: item.itemnum })
                .then(() => {
                    console.log(this.product)
                    this.upsertCartItem(
                        {
                            item: this.product,
                            quantity: item.amount
                        }
                    );
                })
            });
        },

        toPrice(price) {
            return parseFloat(price).toFixed(2).replace('.',',') + "€";
        },
    }
}
</script>
