<template>
    <v-row>
        <v-col cols="12">
            <v-card flat outlined v-if="userAccount && userAccount.isCommAgent">
                <v-card-title v-if="!selectedAccount">
                    Kein Kunde ausgewählt
                    <v-spacer />
                    <v-btn :disabled="cart && cart.length > 0" icon color="primary" @click.stop="accountSelectDialog = !accountSelectDialog">
                        <v-icon>mdi-account-switch-outline</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-title v-else>
                    Gewählter Kunde: {{ selectedAccount.lastname }}
                    <v-spacer />
                    <v-btn :disabled="cart && cart.length > 0" icon color="red lighten-2" @click.stop="onUnsetAccount">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn :disabled="cart && cart.length > 0" icon color="primary" @click.stop="accountSelectDialog = !accountSelectDialog">
                        <v-icon>mdi-account-switch-outline</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="mt-n6 pb-5" v-if="cart && cart.length > 0">
                    Ein Wechsel des Kunden ist nicht möglich, solange sich Artikel im Warenkorb befinden!
                </v-card-text>

                <v-card-text class="mt-n6 pb-5" v-else>
                    Wechseln Sie bei Bedarf den Kunden.
                </v-card-text>
            </v-card>
        </v-col>

        <!-- Account search dialog -->
        <v-dialog v-model="accountSelectDialog" max-width="500">
            <v-card>
                <v-card-title class="px-4">
                    <v-text-field
                        v-model="search"
                        type="search"
                        append-icon="mdi-magnify"
                        placeholder="Kundennummer, Name, PLZ"
                        hide-details
                        @keyup="handleChangeSearchInput()"
                        clearable
                        @click:clear="resetCustomerSearch()"
                    />
                </v-card-title>

                <!-- Accounts -->
                <v-list v-if="accounts && accounts.length > 0">
                    <v-list-item @click="handleClickSetAccount(account.ID)" link v-for="account in accounts" :key="account.customernum">
                        <v-list-item-content>
                            <v-list-item-title>{{ account.customernum }}</v-list-item-title>
                            <v-list-item-subtitle>{{ account.lastname }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ account.postalcode }} {{ account.city }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-list v-if="search && accounts && accounts.length == 0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-center">Kein Kunde gefunden</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-card-actions></v-card-actions>

                <v-overlay
                    absolute
                    :value="loading"
                    >
                    <v-progress-circular color="primary" indeterminate />
                </v-overlay>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import accountService from "@/modules/store/account/services/account.service";
import _ from "lodash";

export default {
    data: () => ({
        accountSelectDialog: false,
        search: null,
        accounts: null,
        loading: false,
    }),

    computed: {
        ...mapGetters("account", ["userAccount", "selectedAccount"]),
        ...mapGetters("auth", ["userId"]),
        ...mapGetters("persistedCart", ["cart"]),

        accesslist: function() {
            return _.map(this.userAccount?.accesslist, 'ID');
        }
    },

    methods: {
        ...mapActions("account", ["getAssignedAccount", "setAccount", "unsetAccount"]),
        ...mapActions("persistedCart", ["getCartByAccountId"]),

        handleChangeSearchInput: _.debounce(function() {
            if (this.search.length < 3) {
                return false;
            }

            this.accounts = null;
            this.loading = true;

            this.searchAccounts();
        }, 500),

        async searchAccounts() {
            this.accounts = null;

            try {
                this.accounts = await accountService.searchAccounts({ search: this.search, accesslist: this.accesslist });

                this.loading = false;
            } catch (error) {
                console.log("error search", error)
                this.loading = false;
                this.accounts = [];
            }
        },

        handleClickSetAccount(accountId) {
            this.getAssignedAccount(accountId)
                .then(() => {
                    this.accountSelectDialog = false;
                });

            this.getCartByAccountId(accountId);
        },

        resetCustomerSearch() {
            this.search = null;
            this.accounts = null;
        },

        onUnsetAccount() {
            this.unsetAccount();
            
            this.getCartByAccountId(this.userId);
        }
    }
}
</script>

<style>

</style>
